.navbar-item{
    padding-left: 2rem !important;
    padding-right: 1rem !important;
    text-align: center !important;
    font-weight: bold !important;
    font-size: large !important;
    text-align: center !important;
}

.imagenRanco{
    width: auto !important;
}

.navbar-item img{
    max-height: 3.5rem !important;
}

/* .card{
        top: 100% !important;
        padding-right: 0% !important;
        position: relative !important;
        width: 40% !important;
        left: 35% !important;
} */


.hero-foot{
background-image: url('/image/cerezas.jpg') !important;
background-position: center center !important;
background-repeat:  no-repeat !important;
background-attachment: fixed !important;
background-size:  cover !important;


}

/* .homeback{
    background-image: url('https://a-static.besthdwallpaper.com/rojo-cereza-papel-pintado-1920x1080-2849_48.jpg') !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    background-color: none !important;
    position: absolute !important;
    height: 70% !important;
    width: 100% !important;

} */
/* .card-content {
    background-color: transparent !important;
    padding: 1.7rem !important;

} */
.column{
    padding-right: 12% !important;
    padding-left: 10% !important;
}

.container {
    max-width: 78%!important;
}

.label {
    text-align: left !important;
}

.footerID {
    position: absolute !important;
    bottom: 0% !important;
    width: 100% !important;
    height: 25% !important;
    left: 0% !important;
    background-color: white !important;
}


.footer {
    background-color: white !important;
    padding: 3rem 1.5rem 6rem !important;
}

.input{
    box-shadow: inset 0 0.0625em 0.125em rgba(10,10,10,.05);
    /* max-width: 100%; */
    width: 87% !important;
    right: 7% !important;
}
.textarea {
    box-shadow: inset 0 0.0625em 0.125em rgba(10,10,10,.05);
    /* max-width: 100%; */
    width: 87% !important;
    right: 1% !important;
}

.spanletter{
color:#3273dc;
}

.icon {
    align-items: center !important;
    display: inline-flex !important;
}

.button .icon:first-child:last-child {
    margin-left: 0 !important;
    margin-right: 0 !important;
}

.buttons {
margin-right: 40% !important;
}

.buttons:last-child {
    margin-bottom: 0rem !important;
}

.columns{
    flex-grow: 1 !important;
    margin: 0 auto !important;
    position: relative !important;
    width: auto !important;
}
.formulariop{
margin-right: 0% !important;
margin-left: 3% !important;

}

.container2{
    background-image: url('https://frutashrg.com/wp-content/uploads/2018/05/crops-cherries.jpg') !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    background-color: none !important;
    position: relative !important;
    width: 100% !important;
}

.container{
    background-color: white !important;
}

.navbar.is-light {
    background-color: white !important;
    color: rgba(0,0,0,.7) !important;
}

.select.is-fullwidth {
    width: 100% !important;
    padding-right: 12% !important;
}

 .selecetext{
    padding-left: 2.25em !important;
} 


  .field-body > .field:not(.is-narrow) {box-flex: 1 !important;  flex-grow: 1 !important;}
  .field-body>.field{ flex:1 !important;}

  .navbar-link:not(.is-arrowless)::after, .select:not(.is-multiple):not(.is-loading)::after {
    left: 80% !important;
  }

  .columns.is-vcentered {
    -webkit-box-align: center !important;
       -ms-flex-align: center !important;
          align-items: center !important;
          margin: auto !important;
  }

  .swal-title {
      color:red;
  }